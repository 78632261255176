<template>
  <div class="page-container">
    <page-header v-if="!setId"></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在地区</span>
                      <span class="descriptions-item-content">
                        {{ farmer && [farmer.province, farmer.city, farmer.area].join('') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">详细地址</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.address }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;" >
            <div class="descriptions-header">
              <div class="descriptions-title">设计信息</div>
            </div>
            <div class="descriptions-view" v-if="metadata">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">组件块数</span>
                      <span class="descriptions-item-content">
                          {{ designData && designData.number }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">组件尺寸</span>
                      <span class="descriptions-item-content">
                          {{ designData && designData.size }}m
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">组件功率</span>
                      <span class="descriptions-item-content">
                          {{ designData && designData.power }}kw
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">逆变器功率</span>
                      <span class="descriptions-item-content">
                          {{ designData && designData.npower }}kw
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">设计文件</span>
                      <span class="descriptions-item-content">
                        <file-list :design-data="designData"></file-list>
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">

                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;" >
            <div class="descriptions-header">
              <div class="descriptions-title">项目物料</div>
            </div>
            <div class="descriptions-view" v-if="metadata">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">物料状态</span>
                      <span class="descriptions-item-content">
                          {{ designStatusMapper(metadata.state) }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">发货清单</span>
                      <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                        <file-list :design-data="{files: metadata.order}"></file-list>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">司机姓名</span>
                      <span class="descriptions-item-content">
                          {{ metadata.driverName }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">司机电话</span>
                      <span class="descriptions-item-content">
                          {{ metadata.driverPhone }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">收货人名称</span>
                      <span class="descriptions-item-content">
                          {{ metadata.takeName }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">收货人电话</span>
                      <span class="descriptions-item-content">
                          {{ metadata.takePhone }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">收货人地址</span>
                      <span class="descriptions-item-content">
                          {{ metadata.area || '' }}
                          {{ metadata.address || ''  }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="metadata.state === 'ING' && checkPermission('wl:sh')"
               class="descriptions"
               style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">操作</div>
            </div>
            <div class="descriptions-view">
              <el-button @click="closePage">取消</el-button>
              <el-button type="primary" @click="receipt">确认收货</el-button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {getMaterialInfo, materialGet} from "@/api/apis";
import {exportPicture, viewImages} from "@/utils/common";
import helper, {projectMaterialStatusMapper} from "@/utils/helper";
import checkPermission from "@/utils/checkPermission";
import FileList from "@/components/FileList";
export default {
  name: "MaterialInfo",
  components: {FileList, PageHeader},
  data(){
    return {
      getData: getMaterialInfo,
      id: '',
      metadata: {},

      farmer: null,
      workOrder: null,
      roofTypeModel: null,
      user: null,
      designData: {},
    }
  },
  props: {
    setId: {
      type: String,
      default(){
        return ''
      }
    }
  },
  computed: {
    thisId(){
      return this.setId || this.$route.params.id
    }
  },
  mounted() {
    this.id = this.thisId
    this.getInfo()
  },
  methods: {
    checkPermission,
    designStatusMapper: projectMaterialStatusMapper,
    viewImages,
    /**
     * 取消
     */
    closePage(){
      this.$router.replace('/admin/material')
    },
    /**
     * 确认收货
     */
    receipt(){
      this.$confirm('确认收货吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({ fullscreen: true })
        materialGet(this.id).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.getInfo()
          }, 500)
        }).finally(() => {
          loading.close()
        })
      })
    },
    //---------------------------------------------------------------
    /**
     * 点击文件
     * @param file
     */
    onPreviewHandler(file){
      const { url, name } = file
      exportPicture(url, name)
    },
    /**
     *
     */
    showFileList(item){
      const filesStr = item?.files || ''
      const files = []
      filesStr.split(',').forEach(iter => {
        const name = ((iter || '').split('/') || []).reverse()[0]
        iter && files.push({
          name: name,
          url: helper.getSourceUri(iter),
          value: iter
        })
      })
      return files
    },
    /**
     * 获取数据
     */
    getInfo(){
      if (this.id) {
        this.getData(this.id).then(res => {
          this.metadata = res
          //
          this.farmer = this.metadata?.farmer
          this.workOrder = this.metadata?.workOrder
          this.roofTypeModel = this.metadata?.workOrder?.roofTypeModel
          this.user = this.metadata?.workOrder?.user
          this.designData = this.metadata?.design?.designData
        })
      } else {
        this.$message.error('页面异常, 请返回重新进入')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>