<template>
  <div class="page-container">
    <page-header v-if="!setId"></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">开户行</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.bankName }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">身份证号</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.idNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">银行卡号</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.bankNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在地区</span>
                      <span class="descriptions-item-content">
                        {{ farmer && [farmer.province, farmer.city, farmer.area].join('') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">详细地址</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.address }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">结算信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">结算图片</span>
                      <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                        <el-image style="width: 160px;height: 90px;margin-right: 10px;margin-bottom: 10px;"
                                  fit="cover"
                                  :preview-src-list="viewImages(metadata.files)"
                                  v-for="iter in viewImages(metadata.files)"
                                  :key="iter"
                                  :src="iter"></el-image>
                      </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {getPayById} from "@/api/apis";
import {exportPicture, viewImages} from "@/utils/common";
import helper, {gridConnectionStatusMapper, statusMapper} from "@/utils/helper";
import {INTENTION_CONSUMPTION_STATE} from "@/utils/config";
export default {
  name: "SettlementInfo",
  components: {PageHeader},
  data(){
    return {
      getData: getPayById,
      id: '',
      metadata: {},

      farmer: null,
      workOrder: null,
      roofTypeModel: null,
      user: null,
      designData: {},
      INTENTION_CONSUMPTION_STATE,
      designDataFiles:[],
    }
  },
  props: {
    setId: {
      type: String,
      default(){
        return ''
      }
    }
  },
  computed: {
    thisId(){
      return this.setId || this.$route.params.id
    }
  },
  mounted() {
    this.id = this.thisId
    this.getInfo()
  },
  methods: {
    gridConnectionStatusMapper,
    viewImages,
    statusMapper,
    /**
     * 点击文件
     * @param file
     */
    onPreviewHandler(file){
      const { url, name } = file
      exportPicture(url, name)
    },
    /**
     *
     */
    showFileList(item){
      const filesStr = item?.files || ''
      const files = []
      filesStr.split(',').forEach(iter => {
        const name = ((iter || '').split('/') || []).reverse()[0]
        iter && files.push({
          name: name,
          url: helper.getSourceUri(iter),
          value: iter
        })
      })
      return files
    },
    /**
     * 获取数据
     */
    getInfo(){
      if (this.id) {
        this.getData(this.id).then(res => {
          this.metadata = res
          //
          this.farmer = this.metadata?.farmer
          this.workOrder = this.metadata?.workOrder
          this.roofTypeModel = this.metadata?.workOrder?.roofTypeModel
          this.user = this.metadata?.workOrder?.user
          this.designData = this.metadata?.designData

          this.designDataFiles = this.showFileList(this.metadata)

        })
      } else {
        this.$message.error('页面异常, 请返回重新进入')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>