<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div style="padding: 0 0 10px;">
        <el-button-group>
          <el-button v-for="iter in detailsList"
                     :key="iter.label"
                     @click="switchInfo(iter)"
                     style="margin-right: 2px;"
                     size="mini"
                     :type="iter.select ? 'primary' : ''">{{ iter.label }}</el-button>
        </el-button-group>
      </div>
      <div class="page-card">
        <div class="page-card-body" style="padding: 20px;">
          <div class="descriptions">
            <div class="descriptions-header" v-if="currValue === 'BASIC_INFORMATION'">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view" v-if="userInfo && currValue === 'BASIC_INFORMATION'">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">
                        {{ userInfo.type == 0 ? '姓名' : '业主姓名' }}
                      </span>
                      <span class="descriptions-item-content">{{ userInfo.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ userInfo.phone }}</span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row" >
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">身份证号</span>
                      <span class="descriptions-item-content">{{ userInfo.idNo }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">身份证正面</span>
                      <span class="descriptions-item-content">
                        <el-image :src="getSourceUrl(userInfo.idNoFrontImg)"
                                  style="width: 160px;height: 90px;"
                                  fit="cover"
                                  :preview-src-list="[getSourceUrl(userInfo.idNoFrontImg)]"></el-image>
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">身份证反面</span>
                      <span class="descriptions-item-content">
                        <el-image :src="getSourceUrl(userInfo.idNoOppositeImg)"
                                  style="width: 160px;height: 90px;"
                                  fit="cover"
                                  :preview-src-list="[getSourceUrl(userInfo.idNoOppositeImg)]"></el-image>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row" >
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">银行卡号</span>
                      <span class="descriptions-item-content">{{ userInfo.bankNo }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">银行卡照片</span>
                      <span class="descriptions-item-content">
                        <el-image :src="getSourceUrl(userInfo.bankNoImg)"
                                  style="width: 160px;height: 90px;"
                                  fit="cover"
                                  :preview-src-list="[getSourceUrl(userInfo.bankNoImg)]"></el-image>
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">房产证照片</span>
                      <span class="descriptions-item-content"
                            v-if="userInfo.houseCertificateImg">
                        <el-image :src="getSourceUrl(userInfo.houseCertificateImg)"
                                  style="width: 160px;height: 90px;"
                                  :preview-src-list="[getSourceUrl(userInfo.houseCertificateImg)]"></el-image>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">开户行</span>
                      <span class="descriptions-item-content">
                        {{ userInfo && userInfo.bankName }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在地区</span>
                      <span class="descriptions-item-content">{{ [userInfo.province, userInfo.city, userInfo.area].join('') }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">详细地址</span>
                      <span class="descriptions-item-content">
                        {{ userInfo.address }}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">备注</span>
                      <span class="descriptions-item-content">
                        {{ userInfo.remarks }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">

                  </td>
                  <td class="descriptions-item" colspan="1">

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- 踏勘信息 -->
            <div v-if="setId && currValue === 'TECHNICAL_SURVEY'">
              <survey-info :set-id="setId"></survey-info>
            </div>
            <!-- 设计信息 -->
            <div v-if="setId && currValue === 'DESIGN'">
              <design-info api-prefix="design" :farmer-id="id" :set-id="setId"></design-info>
            </div>
            <!-- 物料信息 -->
            <div v-if="setId && currValue === 'MATERIAL'">
              <material-info :set-id="setId"></material-info>
            </div>
            <!-- 施工验收 -->
            <div v-if="setId && currValue === 'ACCEPTANCE'">
              <acceptance-info :set-id="setId"></acceptance-info>
            </div>
            <!-- 并网详情 -->
            <div v-if="setId && currValue === 'GRID_CONNECTION'">
              <grid-connection-info :set-id="setId"></grid-connection-info>
            </div>
            <!-- 合同详情 -->
            <div v-if="setId && currValue === 'CONTRACT'">
              <div class="dashboard-container">
                <div class="page-card">
                  <div class="page-card-body" >
                    <div class="descriptions" style="padding: 20px;">
                      <div class="descriptions-header">
                        <div class="descriptions-title">基础信息</div>
                      </div>
                      <div class="descriptions-view">
                        <table>
                          <tbody>
                          <tr class="descriptions-row">
                            <td class="descriptions-item" colspan="1">
                              <div class="descriptions-item-container">
                                <span class="descriptions-item-label">姓名</span>
                                <span class="descriptions-item-content">{{ userInfo.name }}</span>
                              </div>
                            </td>
                            <td class="descriptions-item" colspan="1">
                              <div class="descriptions-item-container">
                                <span class="descriptions-item-label">电话号码</span>
                                <span class="descriptions-item-content">{{ userInfo.phone }}</span>
                              </div>
                            </td>
                            <td class="descriptions-item" colspan="1"></td>
                          </tr>
                          <tr class="descriptions-row">
                            <td class="descriptions-item" colspan="1">
                              <div class="descriptions-item-container">
                                <span class="descriptions-item-label">身份证号</span>
                                <span class="descriptions-item-content">
                                  {{ userInfo.idNo }}
                                </span>
                              </div>
                            </td>
                            <td class="descriptions-item" colspan="1">
                              <div class="descriptions-item-container">
                                <span class="descriptions-item-label">银行卡号</span>
                                <span class="descriptions-item-content">
                                  {{ userInfo.bankNo }}
                                </span>
                              </div>
                            </td>
                            <td class="descriptions-item" colspan="1"></td>
                          </tr>
                          <tr class="descriptions-row">
                            <td class="descriptions-item" colspan="1">
                              <div class="descriptions-item-container">
                                <span class="descriptions-item-label">所在地区</span>
                                <span class="descriptions-item-content">
                                  {{ userInfo && [userInfo.province, userInfo.city, userInfo.area].join('') }}
                                </span>
                              </div>
                            </td>
                            <td class="descriptions-item" colspan="1">
                              <div class="descriptions-item-container">
                                <span class="descriptions-item-label">详细地址</span>
                                <span class="descriptions-item-content">
                                  {{ userInfo && userInfo.address }}
                                </span>
                              </div>
                            </td>
                            <td class="descriptions-item" colspan="1"></td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="descriptions" style="padding: 20px;">
                      <div class="descriptions-header">
                        <div class="descriptions-title">合同信息</div>
                      </div>
                      <div class="descriptions-view">
                        <table>
                          <tbody>
                          <tr class="descriptions-row">
                            <td class="descriptions-item" colspan="1">
                              <div class="descriptions-item-container">
                                <span class="descriptions-item-label">签署方式</span>
                                <span class="descriptions-item-content">线下签约</span>
                              </div>
                            </td>
                            <td class="descriptions-item" colspan="1">

                            </td>
                            <td class="descriptions-item" colspan="1"></td>
                          </tr>
                          <tr class="descriptions-row">
                            <td class="descriptions-item" colspan="3">
                              <div class="descriptions-item-container">
                                <span class="descriptions-item-label">合同照片</span>
                                <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                                  <el-image style="width: 160px;height: 90px;margin-right: 10px;margin-bottom: 10px;"
                                            fit="cover"
                                            :preview-src-list="viewImages(contractFiles)"
                                            v-for="iter in viewImages(contractFiles)"
                                            :key="iter"
                                            :src="iter"></el-image>
                                </span>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 结算详情 -->
            <div v-if="setId && currValue === 'SETTLEMENT'">
              <settlement-info :set-id="setId"></settlement-info>
            </div>
            <div v-if="isNull">
              <el-empty description="暂无信息"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {farmerInfo} from "@/api/common";
import PageHeader from "@/components/PageHeader";
import helper from "@/utils/helper";
import {getInfoFarmer} from "@/api/apis";
import SurveyInfo from "@/views/survey/SurveyInfo";
import DesignInfo from "@/views/design/Info";
import MaterialInfo from "@/views/material/Info";
import AcceptanceInfo from "@/views/constructionAcceptance/Info";
import GridConnectionInfo from "@/views/gridConnection/Info";
import SettlementInfo from "@/views/settlement/Info";
import {viewImages} from "@/utils/common";

export default {
  name: 'FarmerInfo',
  components: {SettlementInfo, GridConnectionInfo, AcceptanceInfo, MaterialInfo, DesignInfo, SurveyInfo, PageHeader},
  data(){
    return {
      userInfo: null,
      detailsList: [
        { label: '基础信息', prefix: '', value: 'BASIC_INFORMATION', select: true, },
        { label: '技术踏勘', prefix: 'word-order', value: 'TECHNICAL_SURVEY', select: false, },
        { label: '合同信息', prefix: 'project', value: 'CONTRACT', select: false, },
        { label: '设计信息', prefix: 'design', value: 'DESIGN', select: false, },
        { label: '项目物料', prefix: 'material', value: 'MATERIAL', select: false, },
        { label: '施工验收', prefix: 'work-ing', value: 'ACCEPTANCE', select: false, },
        { label: '并网信息', prefix: 'connection', value: 'GRID_CONNECTION', select: false, },
        { label: '结算信息', prefix: 'pay', value: 'SETTLEMENT', select: false, },
      ],
      currValue: 'BASIC_INFORMATION',
      setId: '',
      isNull: false,
      contractFiles: '',
      id: '',
    }
  },
  mounted() {
    this.id = this.$route.params.id
    if (this.id) {
      this.getFarmerInfo()
    }
  },
  methods: {
    viewImages,
    getSourceUrl: helper.getSourceUri,
    /**
     * 切换用户信息
     */
    switchInfo(iter){
      // 处理重复请求
      if (iter.value === this.currValue) {
        return
      }

      const shoEmpty = () => {
        // -
        this.setId = ''
        this.isNull = true
        //
        this.detailsList.map(iter => iter.select = false)
        iter.select = true
        this.currValue = iter.value
        this.isNull = true
      }

      // 处理合同
      if (iter.value === 'CONTRACT') {
        getInfoFarmer(iter.prefix, this.id).then(res => {
          if (res && res.signature) {
            const { signature } = res
            const signType = signature?.signType
            const state = signature?.state
            // 线上
            if (signature && String(signType) !== '1') {
              //
              const localUrl = signature.localUrl
              // 有合同 打开合同
              if (localUrl) {
                const url = helper.getSourceUri(localUrl)
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.target = '_blank'
                document.body.appendChild(a);
                a.click();
                return
              }
              // 签署中
              if (state === 'PART') {
                shoEmpty()
                return
              }
            }
            // 线下-默认显示空状态
            shoEmpty()

            //
            this.contractFiles = res.signature?.files || ''
            this.setId = 1;
            this.isNull = false
          } else {
            // 选择tab并显示空状态
            shoEmpty()
          }
        })
        return
      }

      // 其他状态-默认显示空状态
      shoEmpty()

      // 处理其他
      if (iter.prefix) {
        getInfoFarmer(iter.prefix, this.id).then(res => {
          if (res) {
            this.setId = res.id
            this.isNull = false
          } else {
            this.isNull = true
          }
        })
      } else {
        // 用户基础信息
        this.isNull = false
      }
    },
    /**
     * 查询
     */
    getFarmerInfo(){
      farmerInfo(this.id).then(res => {
        this.userInfo = res
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>