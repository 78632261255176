<template>
  <div class="page-container">
    <page-header v-if="!setId"></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">身份证号</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.idNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">银行卡号</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.bankNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在地区</span>
                      <span class="descriptions-item-content">
                        {{ farmer && [farmer.province, farmer.city, farmer.area].join('') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">详细地址</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.address }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">并网信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">并网状态</span>
                      <span class="descriptions-item-content">
                          {{ gridConnectionStatusMapper(metadata.state) }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">户号</span>
                      <span class="descriptions-item-content">{{ metadata.generatorNo }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">装机容量</span>
                      <span class="descriptions-item-content">{{ metadata.capacity && metadata.capacity + '瓦' }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">意向消纳方式</span>
                      <span class="descriptions-item-content">{{ statusMapper(metadata.consumeType, INTENTION_CONSUMPTION_STATE, '') }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">是否本人办理</span>
                      <span class="descriptions-item-content" v-if="metadata.oneselfStr === null">是</span>
                      <span class="descriptions-item-content" v-if="metadata.oneselfStr === false">否</span>
                      <span class="descriptions-item-content" v-if="metadata.oneselfStr === true">是</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">供电合同签订日期</span>
                      <span class="descriptions-item-content">{{ metadata.contractTime }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">供电合同</span>
                      <span class="descriptions-item-content">
                        <file-list :design-data="metadata"></file-list>
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                     <div class="descriptions-item-container">
                      <span class="descriptions-item-label">并网图片</span>
                      <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                          <el-image style="width: 160px;height: 90px;margin-right: 10px;margin-bottom: 10px;"
                                    fit="cover"
                                    :preview-src-list="viewImages(metadata.img)"
                                    v-for="iter in viewImages(metadata.img)"
                                    :key="iter"
                                    :src="iter"></el-image>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- 审核信息 -->
          <div v-if="metadata && metadata.state === 'EXAMINE' && checkPermission('bw:examine')"
               class="descriptions"
               style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">审核</div>
            </div>
            <div class="descriptions-view">
              <el-button type="primary" size="mini" @click="examineInfo">审核</el-button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {connectionExamine, getConnectionById} from "@/api/apis";
import {exportPicture, viewImages} from "@/utils/common";
import helper, {gridConnectionStatusMapper, statusMapper} from "@/utils/helper";
import {INTENTION_CONSUMPTION_STATE} from "@/utils/config";
import {examineNotMsg} from "@/pageInteraction/examine/ExamineMsg";
import checkPermission from "@/utils/checkPermission";
import FileList from "@/components/FileList";
export default {
  name: "GridConnectionInfo",
  components: {FileList, PageHeader},
  data(){
    return {
      getData: getConnectionById,
      id: '',
      metadata: {},

      farmer: null,
      workOrder: null,
      roofTypeModel: null,
      user: null,
      designData: {},
      INTENTION_CONSUMPTION_STATE,
      designDataFiles:[],
    }
  },
  props: {
    setId: {
      type: String,
      default(){
        return ''
      }
    }
  },
  computed: {
    thisId(){
      return this.setId || this.$route.params.id
    }
  },
  mounted() {
    this.id = this.thisId
    this.getInfo()
  },
  methods: {
    checkPermission,
    gridConnectionStatusMapper,
    viewImages,
    statusMapper,
    /**
     * 点击文件
     * @param file
     */
    onPreviewHandler(file){
      const { url, name } = file
      exportPicture(url, name)
    },
    /**
     * 审核
     */
    examineInfo(){
      examineNotMsg(info => {
        const params = {
          id: this.id,
          state: info.state,
        }

        const loading = this.$loading({ fullscreen: true })
        connectionExamine(params).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$router.replace('/admin/grid-connection')
          }, 500)
        }).finally(() => {
          loading.close()
        })

      })
    },
    /**
     *
     */
    showFileList(item){
      const filesStr = item?.files || ''
      const files = []
      filesStr.split(',').forEach(iter => {
        const name = ((iter || '').split('/') || []).reverse()[0]
        iter && files.push({
          name: name,
          url: helper.getSourceUri(iter),
          value: iter
        })
      })
      return files
    },
    /**
     * 获取数据
     */
    getInfo(){
      if (this.id) {
        this.getData(this.id).then(res => {
          this.metadata = res
          //
          this.farmer = this.metadata?.farmer
          this.workOrder = this.metadata?.workOrder
          this.roofTypeModel = this.metadata?.workOrder?.roofTypeModel
          this.user = this.metadata?.workOrder?.user
          this.designData = this.metadata?.designData

          this.designDataFiles = this.showFileList(this.metadata)

        })
      } else {
        this.$message.error('页面异常, 请返回重新进入')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>